import { gql } from '@apollo/client'

export const pollResponce = `
_id
dEndDate
aSlides {
  _id
  eMediaType
  eSlideType
  oBgImgUrl {
    sUrl
    # sText
  }
  oMediaUrl {
    sUrl
    sText
    sAttribute
  }
  sTitle
  aField {
    _id
    eMediaType
    nVote
    oMediaUrl {
      sUrl
      sText
    }
    sTitle
  }
  nTotalVote
}
`

export const GET_POLL_BY_ID_FRONT = gql`
  query GetPollByIdFront($input: getPollByIdFrontInput!) {
    getPollByIdFront(input: $input) {
      ${pollResponce}
    }
  }
`

export const LIST_POLL = gql`
  query ListPollFront($input: listPollInput!) {
    listPollFront(input: $input) {
      ${pollResponce}
    }
  }
`
