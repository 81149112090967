import { gql } from '@apollo/client'

export const quizDetail = `
_id
sTitle
sButtonText
oAnswers
oResSlide {
  sMidText
  sLowText
  sHighText
  oBgImgUrl {
    sUrl
  }
}
oBgImgUrl {
  sUrl
}
aQuestions {
  sQuestion
  eType
  oMediaUrl {
    sUrl
    sText
    sAttribute
  }
  eMediaType
  _id
  aPausePoint
  nTotalVote
  oBgImgUrl {
    sUrl
  }
  aAnswers {
    _id
    isCorrect
    nVote
    sAnswer
    eMediaType
    oMediaUrl {
      sUrl
      sText
      sAttribute
    }
  }
  aQuestions {
    _id
    sQuestion
    nTotalVote
    aAnswers {
      _id
      isCorrect
      nVote
      sAnswer
    }
  }
}
`

export const GET_QUIZ_BY_ID_FRONT = gql`
  query GetQuizByIdFront($input: getQuizByIdFrontInput) {
    getQuizByIdFront(input: $input) {
      ${quizDetail}
    }
  }
`
